@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;

}

:not(.has-scroll) {
  scrollbar-color: #252735;
  scrollbar-width: thin;
}

::not(.has-scroll)::-webkit-scrollbar {
  width: 8px
}

::not(.has-scroll)::-webkit-scrollbar-track {
  background-color: #1e1d2b;
}

::not(.has-scroll)::-webkit-scrollbar-thumb {
  background-color: #f5f6f9;;
  border: 1.5px solid #1e1d2b;
  border-radius: 3px
}


/* LiveKit Theme */
[data-lk-theme] {
  --lk-accent-bg: #1d4ed8;
  --lk-accent2: #1e40af;
  --lk-accent3: #47a0fa;
  --lk-accent4: #5babfb;
  --lk-danger: #f91f31;
  --lk-danger2: #fa3343;
  --lk-danger3: #fa4756;
  --lk-danger4: #fb5b68;
  --lk-success: #1ff968;
  --lk-success2: #33fa75;
  --lk-success3: #47fa83;
  --lk-success4: #5bfb90;
  --lk-control-fg: var(--lk-fg);
  --lk-control-bg: var(--lk-bg2);
  --lk-control-hover-bg: var(--lk-bg3);
  --lk-control-active-bg: var(--lk-bg4);
  --lk-control-active-hover-bg: var(--lk-bg5);
  --lk-connection-excellent: #06db4d;
  --lk-connection-good: #f9b11f;
  --lk-connection-poor: #f91f31;
  --lk-font-size: 16px;
  --lk-line-height: 1.5;
  --lk-border-radius: 0.5rem;
  --lk-box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
  --lk-drop-shadow: rgba(255, 255, 255, 0.2) 0px 0px 24px;
  --lk-grid-gap: 0.5rem;
  --lk-control-bar-height: 69px;
  --lk-chat-header-height: 69px
}
[data-lk-theme='dark'] {
  color-scheme: dark;
  --lk-bg: #111;
  --lk-bg2: #1e1e1e;
  --lk-bg3: #2b2b2b;
  --lk-bg4: #373737;
  --lk-bg5: #444444;
  --lk-fg: #fff;
  --lk-fg2: whitesmoke;
  --lk-fg3: #ebebeb;
  --lk-fg4: #e0e0e0;
  --lk-fg5: #d6d6d6;
  --lk-border-color: rgba(255, 255, 255, 0.1);
  --lk-accent-fg: #fff;
  --lk-danger-fg: #fff;
  --lk-success-fg: #fff;
}

[data-lk-theme='light'], 
[data-lk-theme]:not([data-lk-theme='dark']) {
  color-scheme: light;
  --lk-bg: #fff;
  --lk-bg2: #f8f8f8;
  --lk-bg3: #f0f0f0;
  --lk-bg4: #e8e8e8;
  --lk-bg5: #e0e0e0;
  --lk-fg: #111;
  --lk-fg2: #1e1e1e;
  --lk-fg3: #2b2b2b;
  --lk-fg4: #373737;
  --lk-fg5: #444444;
  --lk-border-color: rgba(0, 0, 0, 0.1);
  --lk-accent-fg: #fff;
  --lk-danger-fg: #fff;
  --lk-success-fg: #fff;
}
.lk-participant-tile .lk-participant-placeholder svg > path {
 fill: var(--lk-fg4);
}

.lk-video-container,.lk-camera-off-note{ 
background-color: var(--lk-bg2) !important;
  svg > path {
  fill: var(--lk-fg4);
  }
}
.lk-chat-header .lk-close-button:hover {
  background-color: var(--lk-bg4) !important;
  
}
.lk-participant-metadata-item, .lk-focus-toggle-button{
  background-color: var(--lk-bg4) !important;
}
.lk-close-button {
  svg > path {
    fill: var(--lk-fg4);
    }
}